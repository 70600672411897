<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group
          :label="$t('Número')"
        >
          <b-form-input
            v-if="view === false"
            :id="`sw078s05_${(index + 1)}`"
            v-model="sw078[index].sw078s05"
            placeholder=""
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ getTxt(sw078[index].sw078s05) }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="1">
        <b-form-group
          :label="$t('Fração')"
        >
          <b-form-input
            v-if="view === false"
            :id="`sw078s06_${(index + 1)}`"
            v-model="sw078[index].sw078s06"
            maxlength="2"
            placeholder=""
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ getTxt(sw078[index].sw078s06) }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('Valor patrimonial')"
        >
          <b-input-group
            v-if="view === false"
            :prepend="symbolMoney"
            append=""
            class="input-group-merge"
          >
            <b-form-input
              v-if="view === false"
              :id="`sw078s07_${(index + 1)}`"
              v-model.lazy="sw078[index].sw078s07"
              v-currency="configMoney"
              placeholder=""
              autocomplete="off"
            />
          </b-input-group>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ getTxt(formatPrice(sw078[index].sw078s07)) }} {{ symbolMoney }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        v-if="addBtn === true && view === false"
        md="2"
      >
        <b-form-group
          :label="$t('Ações')"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            @click.stop.prevent="$store.dispatch('listings_form/addNewCaderneta')"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Adicionar') }}</span>
          </b-button>
        </b-form-group>
      </b-col>
      <b-col
        v-if="addBtn === false && view === false"
        md="2"
      >
        <b-form-group
          :label="$t('Ações')"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon"
            @click.stop.prevent="$store.dispatch('listings_form/removeCaderneta', index)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Eliminar') }}</span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { CurrencyDirective } from 'vue-currency-input'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
  },
  directives: {
    Ripple,
    currency: CurrencyDirective,
  },
  props: {
    sw078: {
      type: Array,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => 0,
    },
    addBtn: {
      type: Boolean,
      default: () => false,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      maskDate: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      configMoney,
      symbolMoney,
      formatPrice,
    }
  },
  methods: {
    getTxt(value) {
      if ((value !== null) && (value !== undefined) && (value !== '')) {
        return value
      }
      return '-----'
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
</style>
